<template>
  <v-dialog v-model="data.dialog" persistent :max-width="data.size">
    <v-card>
      <v-toolbar color="info" dark class="header-info">{{
        data.title
      }}</v-toolbar>

      <v-card-text class="mt-5" v-if="inquiries_data.length > 0">
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col
                class="pt-0"
                v-for="(item, index) in inqueries"
                :key="index"
                cols="6"
              >
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  item.inquery_lang
                }}</label>
                <v-file-input
                  v-if="item.type == 'file'"
                  v-model="inquiries_data[index].answer"
                  show-size
                  counter
                  chips
                ></v-file-input>
                <v-text-field
                  v-else
                  :type="item.type"
                  outlined
                  color="rgba(0,0,0,.6)"
                  class="font-size-input placeholder-lighter text-light-input"
                  v-model="inquiries_data[index].answer"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="dark" class="bg-light" text @click="data.dialog = false">
          {{ $t("form.Close") }}
        </v-btn>
        <v-btn
          class="btn-primary mw-80"
          color="white"
          :loading="data.loader"
          text
          @click="save_form()"
        >
          <span slot="loader">
            <v-progress-circular
              style="width: 20px; height: 20px"
              indeterminate
              color="white"
            ></v-progress-circular>
          </span>
          {{ $t("form.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["data", "inqueries", "inquiries_data"],
  data() {
    return {};
  },
  methods: {
    save_form() {
      //

      this.$emit("save", this.inquiries_data);
    },
  },
};
</script>