<template>
  <v-container fluid class="py-6 profile">
    <Loading v-if="loading"></Loading>
    <Notify ref="myNotify" :details="message"></Notify>
    <v-row>
      <v-col cols="12">
        <v-card class="light print_section">
          <v-app-bar color="light" class="border-left-primary chart_head pt-3">
            <!-- <v-toolbar-title> -->
            <v-select
              class="chart_select"
              :items="years"
              v-model="selected_year"
              label="Year"
              sm="2"
              @change="redraw()"
              :disabled="radioCheck == 1"
            ></v-select>
            <v-radio-group v-model="radioCheck" row v-on:change="redraw()">
              <v-radio label="Monthly" value="0"></v-radio>
              <v-radio label="Yearly" value="1"></v-radio>
              <v-radio label="Quarterly" value="2"></v-radio>
            </v-radio-group>
            <!-- </v-toolbar-title> -->

            <v-select
              class="chart_select"
              :items="total_inquiry"
              v-model="selected_inquiry"
              item-value="id"
              item-text="name"
              label
              return-object
              multiple
              sm="2"
              @change="redraw()"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"
                  >(+{{ selected_inquiry.length - 1 }} others)</span
                >
              </template>
            </v-select>
          </v-app-bar>
          <div id="chartBar">
            <canvas id="myChart" height="100"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-row class="flex-column">
          <!-- info -->
          <v-col cols="12">
            <v-card class="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("Info contact") }}</v-toolbar-title>
              </v-app-bar>
              <v-card class="mx-auto border border-top-none px-5 py-7" title>
                <!-- <div class="px-6 pb-6 pt-0"> -->
                <v-row>
                  <v-col sm="12" cols="12" class="text-center">
                    <v-badge
                      bottom
                      color="bg-gradient-light accent-4 text-dark text-xxs border-radius-md shadow"
                      offset-x="10"
                      offset-y="10"
                      :icon="avatar_src ? '' : 'fa-plus text-xxs'"
                    >
                      <v-avatar rounded width="110" height="110">
                        <img
                          v-if="saved_avatar_src && avatar_src == ''"
                          :src="$baseURL + 'storage/users/' + saved_avatar_src"
                          :lazy-src="
                            $baseURL + 'storage/users/' + saved_avatar_src
                          "
                          alt="Avatar"
                          class="border-radius-lg"
                        />
                        <img
                          v-if="avatar_src"
                          :src="avatar_src"
                          :lazy-src="avatar_src"
                          alt="Avatar"
                          class="border-radius-lg"
                        />
                        <img
                          v-if="
                            (avatar_src == '' || avatar_src == null) &&
                            (saved_avatar_src == '' || saved_avatar_src == null)
                          "
                          src="@/assets/img/no-avatar.jpg"
                          alt="Avatar"
                          class="border-radius-lg"
                        />
                        <span
                          @click="removeImage()"
                          v-if="avatar_src"
                          class="
                            v-badge__badge
                            bg-gradient-light
                            accent-4
                            text-dark text-xxs
                            border-radius-md
                            shadow
                          "
                          style="
                            inset: calc(100% - 10px) auto auto calc(100% - 10px);
                            z-index: 9;
                            cursor: pointer;
                          "
                        >
                          <i
                            aria-hidden="true"
                            class="
                              v-icon
                              notranslate
                              fa fa-times
                              text-xxs
                              theme--light
                            "
                          ></i>
                        </span>

                        <v-file-input
                          class="custom-avatar"
                          id="files"
                          ref="files"
                          accept="image/png, image/jpeg, image/bmp"
                          @change="handleFilesUpload"
                          v-model="files"
                        />
                      </v-avatar>
                    </v-badge>
                    <div class="text-center mt-6">
                      <v-btn
                        color="#cb0c9f"
                        class="
                          font-weight-bolder
                          btn-default
                          bg-gradient-default
                          py-4
                          px-3
                          ms-auto
                          mt-sm-auto mt-4
                        "
                        small
                        @click="DeleteImage()"
                        :loading="loadingImage"
                      >
                        <span slot="loader">
                          <v-progress-circular
                            style="width: 20px; height: 20px"
                            indeterminate
                            color="white"
                          >
                          </v-progress-circular>
                        </span>
                        {{ $t("Delete Image") }}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col sm="12" cols="12">
                    <Form
                      @save="handleUpdate"
                      :loader="loadingInfo"
                      :data="form_info_basic"
                    ></Form>
                  </v-col>
                  <!-- reset password -->
                  <v-col sm="12" cols="12">
                    <h4 class="mb-8">{{ $t("auth.Change Password") }}</h4>
                    <v-form ref="resetPasswordForm">
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >{{ $t("current password") }}</label
                          >
                          <v-text-field
                            outlined
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show3 ? 'text' : 'password'"
                            @click:append="show3 = !show3"
                            v-model="passwords.current_password"
                            :rules="rules.current_password"
                            lazy-validation
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                              font-size-input
                              placeholder-lighter
                              mt-2
                              mb-4
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >{{ $t("user.New password") }}</label
                          >
                          <v-text-field
                            outlined
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            v-model="passwords.password"
                            :rules="rules.password"
                            lazy-validation
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                              font-size-input
                              placeholder-lighter
                              mt-2
                              mb-4
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >{{ $t("user.Confirm new password") }}</label
                          >
                          <v-text-field
                            outlined
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                            v-model="passwords.password_confirmation"
                            :rules="rules.password_confirmation"
                            lazy-validation
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                              font-size-input
                              placeholder-lighter
                              mt-2
                              mb-4
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div class="text-right">
                        <v-btn
                          @click="ResetPassword()"
                          class="btn-primary py-5 px-6 mb-"
                          :loading="loadingPassword"
                          small
                        >
                          <span slot="loader">
                            <v-progress-circular
                              style="width: 20px; height: 20px"
                              indeterminate
                              color="white"
                            >
                            </v-progress-circular>
                          </span>
                          {{ $t("auth.Change Password") }}
                        </v-btn>
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
                <!-- </div> -->
              </v-card>
            </v-card>
          </v-col>
          <Tab
            :inquiries_data="inquiries_data_col1"
            :tabs="tab_form_col_1"
            @save="save_form"
            :loader="loader_form"
            :type="'1'"
          >
          </Tab>
          <!-- :loader="loader_form" -->
        </v-row>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6"> </v-col> -->
      <v-col cols="12" sm="12" md="6">
        <v-row class="flex-column">
          <v-col
            cols="12"
            v-if="customer.inquiries && customer.inquiries.length > 0"
          >
            <v-card class="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("Returns") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="add_inquiry()">
                  <v-badge
                    color="red"
                    overlap
                    :content="inqueries.length"
                    v-if="inqueries.length > 0"
                  >
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-badge>
                </v-btn>
              </v-app-bar>
              <v-card class="mx-auto border border-top-none" title>
                <v-list-item
                  class="py-3"
                  v-for="(item, i) in customer.inquiries"
                  :key="i"
                >
                  <v-list-item-content class="pb-0 pt-3">
                    <v-row>
                      <v-col cols="3" class="pt-0">
                        <div class="d-flex flex-column">
                          <v-btn
                            icon
                            class="text-typo"
                            @click="edit_inquery(item)"
                          >
                            <v-icon size="15">mdi-pencil</v-icon>
                          </v-btn>
                          <div>
                            <h6
                              v-if="item.name == 'currency'"
                              class="
                                mb-1
                                text-body text-lg
                                font-weight-bold
                                text-h5
                                mx-3
                              "
                            >
                              SAR
                            </h6>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <div class="d-flex flex-column text-end">
                          <span
                            class="text-xs text-typo mb-3 font-weight-bold"
                            >{{ item.inquery_lang }}</span
                          >
                          <div v-if="item.answer != null">
                            <a
                              v-if="item.type == 'file'"
                              target="_blank"
                              class="text-decoration-none"
                              :href="$baseURL + item.answer"
                            >
                              <v-btn
                                color="#fff"
                                class="
                                  text-dark
                                  font-weight-bolder
                                  py-6
                                  shadow-0
                                  px-0
                                "
                                small
                                simple
                              >
                                <v-icon size="18" class="rotate-180"
                                  >fas fa-file-pdf text-lg me-1</v-icon
                                >
                                {{ $t("ticket.file") }}
                              </v-btn>
                            </a>
                            <h6
                              v-else
                              class="
                                mb-1
                                text-body text-lg
                                font-weight-bold
                                text-h4
                              "
                            >
                              <span>{{ item.answer }}</span>
                            </h6>
                          </div>
                          <h6
                            class="
                              mb-1
                              text-body text-lg
                              font-weight-bold
                              text-h4
                            "
                            v-else
                          >
                            -
                          </h6>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="repeatable_inquiries">
            <v-card class="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("Monthly Inquiries") }}</v-toolbar-title>
              </v-app-bar>
              <v-card class="mx-auto border border-top-none" title>
                <v-list-item
                  class="py-3"
                  v-for="(item, year) in repeatable_inquiries"
                  :key="year"
                >
                  <v-list-group
                    prepend-icon="mdi-clipboard-text"
                    no-action
                    class="w-100 px-0 py-3 stages"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ year }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      class="py-3"
                      v-for="(itemMonth, month) in item"
                      :key="month"
                    >
                      <v-list-group
                        prepend-icon="mdi-clipboard-text"
                        no-action
                        class="w-100 px-0 py-3 stages"
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              month | getMonth
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <div class="mt-6">
                          <v-row>
                            <v-col
                              class="pt-0"
                              v-for="(inquery, index) in itemMonth"
                              :key="index"
                              cols="12"
                            >
                              <v-row>
                                <v-col cols="12" sm="10">
                                  <div class="position-relative">
                                    <label
                                      class="
                                        text-xs text-typo
                                        font-weight-bolder
                                        ms-1
                                      "
                                      >{{ inquery.inquery_lang }}</label
                                    >
                                  </div>
                                </v-col>
                              </v-row>
                              <div class="position-relative">
                                <v-text-field
                                  :type="inquery.type"
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  class="
                                    font-size-input
                                    placeholder-lighter
                                    text-light-input
                                  "
                                  v-model="
                                    inquiries_data_monthly[year][month][index]
                                      .answer
                                  "
                                ></v-text-field>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col :class="classLang" cols="12">
                              <v-btn
                                class="btn-primary mw-80"
                                color="white"
                                text
                                @click="save_inquery_repeatble(year, month)"
                                >{{ $t("form.save") }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </v-list-group>
                    </v-list-item>
                  </v-list-group>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          <!-- <v-col cols="12">
          </v-col>-->
          <Tab
            :inquiries_data="inquiries_data_col2"
            :tabs="tab_form_col_2"
            @save="save_form"
            :loader="loader_form"
            :type="'2'"
          >
          </Tab>
          <!-- :loader="loader_form" -->
        </v-row>
      </v-col>
    </v-row>
    <Modal
      :data="modal_data"
      :inquiries_data="inquiries_data"
      :inqueries="inqueries_all"
      @save="save_inquery"
    ></Modal>
  </v-container>
</template><script>
import Modal from "./Modal.vue";
import Tab from "../../Components/Tab.vue";
import Form from "../../Components/Old/Form.vue";
import Notify from "../../Components/Old/Notify.vue";
import Loading from "../../Components/Loading.vue";
import CustomerService from "../../../services/customer.service";
import { createNamespacedHelpers } from "vuex";
import Chart from "chart.js/auto";

const { mapState, mapActions } = createNamespacedHelpers("customer");
export default {
  name: "Profile-Form",
  components: {
    Form,
    Modal,
    Loading,
    Notify,
    Tab,
  },
  data() {
    return {
      files: null,
      active: false,
      avatar_src: "",
      saved_avatar_src: "",
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        company: "",
        email: "",
      },
      passwords: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      confirm: false,
      show1: false,
      show2: false,
      show3: false,
      form_info_basic: [
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("First Name"),
          error: null,
          value: "",
          value_text: "first_name",
          rules: [
            (v) => !!v || this.$i18n.t("first name is required"),
            (v) =>
              v.length <= 20 ||
              this.$i18n.t("Name must be less than 20 characters"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("Last Name"),
          error: null,
          value: "",
          value_text: "last_name",
          rules: [
            (v) => !!v || this.$i18n.t("last name is required"),
            (v) =>
              v.length <= 20 ||
              this.$i18n.t("auth.Name must be less than 20 characters"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("auth.Email Address"),
          error: null,
          value: "",
          value_text: "email",
          rules: [
            (v) => !!v || this.$i18n.t("auth.E-mail is required"),
            (v) =>
              /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
                v
              ) || this.$i18n.t("auth.E-mail must be valid"),
          ],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("auth.Phone Number"),
          error: null,
          value: "",
          value_text: "phone",
          rules: [(v) => !!v || this.$i18n.t("auth.Phone Number is required")],
        },
        {
          col: 12,
          type: "text",
          label: this.$i18n.t("Company Name"),
          error: null,
          value: "",
          value_text: "company",
          rules: [
            // (v) => !!v || this.$i18n.t("auth.Phone Number is required")
          ],
        },
      ],
      tab_form_col_1: [],
      tab_form_col_2: [],
      rules: {
        current_password: [
          (v) => !!v || this.$i18n.t("current password is required"),
        ],
        password: [
          (v) => !!v || this.$i18n.t("auth.Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$i18n.t("auth.minimum 6 characters at least"),
        ],
        password_confirmation: [
          (v) => !!v || this.$i18n.t("auth.Password Confirmation is required"),
          (v) =>
            v == this.passwords.password ||
            this.$i18n.t(
              "auth.Password and password confirmation does not match"
            ),
        ],
      },
      loadingInfo: false,
      loadingImage: false,
      loadingPassword: false,
      errors: {},
      error_msg: "",
      modal_data: {
        size: "700px",
        title: "",
        dialog: false,
        loader: false,
      },
      info: [],
      activeTab: 1,
      totalSteps: 7,
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      loader_form: false,
      type_modal: null,

      inquiries_data_col1: [],
      inquiries_data_col2: [],
      inquiries_data_monthly: [],
      total_inquiry: [],
      selected_inquiry: [],
      labels: [],
      datasets: [],
      chart_data: [],
      radioCheck: "0",
      years: [],
      selected_year: null,
    };
  },
  computed: {
    ...mapState([
      "customer",
      "inqueries_all",
      "inqueries",
      "inquiries_data",
      "repeatable_inquiries",
    ]),
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
        // "pl-0": !this.$vuetify.rtl,
        // "pr-0": this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions([
      "Profile",
      "updateInqueryInCustomer",
      "addInquery",
      "editInquery",
      "saveInqueryInCustomer",
      "saveAnswerRepeatableInquiries",
    ]),
    getDetails() {
      this.loading = true;
      this.Profile().then(
        (response) => {
          this.loading = false;
          this.inquiries_data_monthly = this.repeatable_inquiries;
          var customer = this.customer;
          var count = response.data.tabs.length;

          if (count % 2 == 0) {
            // if count double
            var length = count / 2;
            this.set_tabs_into_2_array(response.data.tabs, count, length);
          } else {
            var count1 = count - 1;
            var length = count1 / 2;
            this.set_tabs_into_2_array(response.data.tabs, count1, length);
            this.tab_form_col_1.push(response.data.tabs[count1]);
            var array = this.set_inquery_data(response.data.tabs[count1]);
            this.inquiries_data_col1.push(array);
          }
          this.saved_avatar_src = customer.users.avatar;
          this.form_info_basic.map(function (item) {
            if (customer.users[item.value_text] != null)
              item.value = customer.users[item.value_text];
          });
        },
        (error) => {
          this.loading = false;
          // console.log(error);
        }
      );
    },
    set_tabs_into_2_array(data, count, length) {
      var y = 0;
      var section = 2;
      var i;
      for (i = 0; i < length; i++) {
        const element = data[i];
        y++;

        if (section == 1) {
          this.tab_form_col_1.push(element);
          var array = this.set_inquery_data(element);
          this.inquiries_data_col1.push(array);
        } else {
          this.tab_form_col_2.push(element);
          var array = this.set_inquery_data(element);
          this.inquiries_data_col2.push(array);
        }
        if (y == length) {
          if (length != count) {
            length = length * 2;
            section = 1;
          }
        }
      }
    },
    set_inquery_data(element) {
      var newarray = { arr: [] };
      for (let y = 0; y < element.get_inquiries.length; y++) {
        const elementy = element.get_inquiries[y];
        var is_file = 0,
          id = null,
          answer = null,
          file = null;
        if (elementy.inputs.type == "file") {
          is_file = 1;
        }
        if (elementy.register_form_answers != null) {
          id = elementy.register_form_answers.id;
          if (elementy.register_form_answers.is_file == 0) {
            answer = elementy.register_form_answers.answer;
          } else {
            file = elementy.register_form_answers.answer;
          }
        }
        newarray.arr.push({
          answer: answer,
          tab_id: elementy.pivot.tab_id,
          is_file: is_file,
          inquiry_id: elementy.pivot.inquiry_id,
          id: id,
          file: file,
        });
        // }
      }
      return newarray;
    },
    edit_inquery(item) {
      // 1 => edit
      this.type_modal = 1;
      console.log(item);
      this.modal_data.dialog = true;
      this.modal_data.title =
        this.$i18n.t("inquiry.Edit Answer For Inquiry") +
        " " +
        item.inquery_lang;
      this.editInquery(item);
    },
    add_inquiry() {
      // type modal is depend on edit answer or answer inquery
      // 0 => add
      this.type_modal = 0;
      this.modal_data.dialog = true;
      this.modal_data.title = this.$i18n.t("inquiry.Inqieries");
      this.addInquery();
    },
    save_inquery(data) {
      console.log(data);
      this.modal_data.loader = true;
      if (this.type_modal == 1) {
        if (data[0].answer != null && data[0].answer != "") {
          var formData = new FormData();
          formData.append(`answer`, data[0].answer);
          formData.append(`type`, data[0].type);
          this.updateInqueryInCustomer({
            id: data[0].row_id,
            data: formData,
          }).then(
            (response) => {
              this.modal_data.loader = false;
              this.modal_data.dialog = false;
              // console.log(response);
              this.message = {
                msg: response.data.message,
                type: "Success",
              };
            },
            (error) => {
              this.modal_data.loader = false;
              // this.modal_data.dialog = false;
              console.log(error);
              this.message = {
                msg: this.$i18n.t("general.there is problem"),
                type: "Danger",
              };
            }
          );
        } else {
          this.modal_data.loader = false;
          this.message = {
            msg: this.$i18n.t("general.please fill field"),
            type: "Danger",
          };
        }
      } else if (this.type_modal == 0) {
        var formData = new FormData();
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          formData.append(`answer[${i}]`, element.answer);
          formData.append(`id[${i}]`, element.id);
          formData.append(`types[${i}]`, element.type);
        }
        this.saveInqueryInCustomer(formData).then(
          (response) => {
            this.modal_data.loader = false;
            this.modal_data.dialog = false;
            // console.log(response);
            this.message = {
              msg: response.data.message,
              type: "Success",
            };
          },
          (error) => {
            this.modal_data.loader = false;
            // this.modal_data.dialog = false;
            console.log(error);
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        );
      }
    },
    save_inquery_repeatble(year, month) {
      var formData = new FormData();
      var inquiries = this.inquiries_data_monthly[year][month];
      console.log(inquiries);
      for (let i = 0; i < inquiries.length; i++) {
        const element = inquiries[i];
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`id[${i}]`, element.id);
      }
      this.saveAnswerRepeatableInquiries(formData).then(
        (response) => {
          this.modal_data.loader = false;
          // console.log(response);
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          this.modal_data.loader = false;
          // this.modal_data.dialog = false;
          console.log(error);
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    activeity(val) {
      this.activeTab = val;
    },
    save_form(data, tab_index, type) {
      console.log(data);
      // this.loader_form = true;
      let formData = new FormData();
      var inqueries = data[tab_index].arr;
      for (let i = 0; i < inqueries.length; i++) {
        const element = inqueries[i];
        formData.append(`id[${i}]`, element.id);
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`tab_id[${i}]`, element.tab_id);
        formData.append(`is_file[${i}]`, element.is_file);
        formData.append(`inquiry_id[${i}]`, element.inquiry_id);
      }
      return CustomerService.add_form_register_inquery(formData).then(
        (response) => {
          // this.loader_form = false;
          console.log(response);
          var new_answer = response.data.data.answer;
          for (let z = 0; z < inqueries.length; z++) {
            const element = inqueries[z];
            for (let m = 0; m < new_answer.length; m++) {
              const elementm = new_answer[m];
              if (
                elementm.tab_id == element.tab_id &&
                elementm.inquiry_id == element.inquiry_id
              ) {
                element.id = elementm.id;
                if (elementm.is_file == 1) {
                  element.answer = null;
                  element.file = elementm.answer;
                  if (type == 1) {
                    this.tab_form_col_1[tab_index].get_inquiries[
                      z
                    ].register_form_answers = elementm;
                  } else if (type == 2) {
                    this.tab_form_col_2[tab_index].get_inquiries[
                      z
                    ].register_form_answers = elementm;
                  }
                }
              }
            }
          }
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
        },
        (error) => {
          // this.loader_form = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    passwordsvalidate() {
      return this.$refs.resetPasswordForm.validate();
    },
    handleUpdate(valid) {
      this.loadingInfo = true;
      if (valid) {
        this.error_msg = "";
        const formData = new FormData();
        if (this.files != null) formData.append("avatar", this.files);
        this.form_info_basic.map(function (item) {
          if (item.value != null) {
            formData.append(item.value_text, item.value);
          }
        });

        axios({
          url: "users/update/" + this.$store.state.auth.user.id,
          data: formData,
          method: "POST",
        })
          .then((response) => {
            this.loadingInfo = false;
            let user = response.data.user;
            this.form_info_basic.map(function (item) {
              item.value = user[item.value_text];
            });
            this.saved_avatar_src = response.data.user.avatar;
            this.message = {
              msg: response.data.message,
              type: "Success",
            };
          })
          .catch((error) => {
            this.loadingInfo = false;
            if (error && error.response.status == 422) {
              let errors = Object.values(error.response.data.errors);
              this.error_msg = errors.toString();
            } else {
              this.error_msg =
                (error.response && error.response.data.message) ||
                error.message;
            }
            this.message = {
              msg: this.error_msg,
              type: "Danger",
            };
          });
      } else {
        this.loadingInfo = false;
        this.message = {
          msg: "Please Check errors and try again.",
          type: "Danger",
        };
        return;
      }
    },

    removeImage() {
      this.files = null;
      this.avatar_src = "";
    },

    DeleteImage() {
      this.error_msg = "";

      this.$swal({
        title: this.$i18n.t("Are you sure you want to delete profile image?"),
        text: this.$i18n.t("You won't be able to revert this!"),
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("general.delete"),
        cancelButtonText: this.$i18n.t("Keep it"),
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-success",
        },
        preConfirm: (remove) => {
          this.error_msg = "";
          axios({
            url: "users/delete/avatar/" + this.$store.state.auth.user.id,
            method: "POST",
          })
            .then((response) => {
              this.avatar_src = "";
              this.saved_avatar_src = "";
              this.message = {
                msg: response.data.message,
                type: "Success",
              };
            })
            .catch((error) => {
              if (error && error.response.status == 422) {
                let errors = Object.values(error.response.data.errors);
                this.error_msg = errors.toString();
              } else {
                this.error_msg =
                  (error.response && error.response.data.message) ||
                  error.message;
              }
              this.message = {
                msg: this.error_msg,
                type: "Danger",
              };
            });
        },
      });
    },
    ResetPassword() {
      if (this.passwordsvalidate()) {
        this.$swal({
          title: this.$i18n.t(
            "Are you sure you want to change this account password?"
          ),
          text: this.$i18n.t("You won't be able to revert this!"),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t("Change"),
          cancelButtonText: this.$i18n.t("Cancel"),
          customClass: {
            confirmButton: "btn bg-gradient-danger",
            cancelButton: "btn bg-gradient-success",
          },
          preConfirm: (remove) => {
            this.error_msg = "";
            this.loadingPassword = true;

            axios({
              url: "users/change-passwored",
              data: this.passwords,
              method: "POST",
            })
              .then((response) => {
                this.message = {
                  msg: response.data.message,
                  type: "Success",
                };
                this.loadingPassword = false;
              })
              .catch((error) => {
                console.log(error);
                this.loadingPassword = false;
                if (error && error.response.status == 422) {
                  let errors = Object.values(error.response.data.errors);
                  this.error_msg = errors.toString();
                } else {
                  this.error_msg =
                    (error.response && error.response.data.message) ||
                    error.message;
                }
                this.message = {
                  msg: this.error_msg,
                  type: "Danger",
                };
              });
          },
        });
      } else {
        this.loadingPassword = false;
        this.error_msg = this.$i18n.t("Please Check errors and try again");
        return;
      }
    },
    handleFilesUpload() {
      this.files = event.target.files[0];
      this.avatar_src = URL.createObjectURL(event.target.files[0]);
    },
    inquiry_chart() {
      var self = this;
      var crt = new Chart(document.getElementById("myChart"), {
        type: "bar",
        data: {
          labels: self.labels,
          // datasets: [{
          //     label: '# of Votes',
          //     data: self.chart_data,
          //     backgroundColor:'#047a6f',
          //     borderWidth: 1
          // }]
          datasets: self.datasets,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    get_chart_inquiries() {
      var self = this;
      return CustomerService.get_chart_inquiries(
        this.$store.state.auth.user.id
      ).then(
        (response) => {
          response.data.map(function (item) {
            var tmp = {};
            tmp["name"] = item.inquery_lang;
            tmp["id"] = item.inquiry_id;
            self.total_inquiry.push(tmp);
          });
          self.selected_inquiry[0] = self.total_inquiry[0];
          this.get_chart_data();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    get_chart_data() {
      var self = this;
      let inquiries_ids = [];
      this.selected_inquiry.map((a) => inquiries_ids.push(a.id));
      self.labels = [];
      self.datasets = [];

      let formData = new FormData();
      let type;
      if (self.radioCheck == 0) {
        type = "monthly";
      } else if (self.radioCheck == 1) {
        type = "yearly";
      } else {
        type = "quarterly";
      }
      formData.append("type", type);
      inquiries_ids.forEach((item) => formData.append("inquiries[]", item));
      formData.append("user_id", this.$store.state.auth.user.id);
      formData.append("year", self.selected_year);

      return CustomerService.get_chart_data(formData).then(
        (response) => {
          self.labels = response.data.labels;
          self.datasets = response.data.datasets;
          this.inquiry_chart();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    redraw() {
      document.getElementById("myChart").remove();
      document
        .getElementById("chartBar")
        .insertAdjacentHTML(
          "beforeend",
          '<canvas id="myChart" height="100"></canvas>'
        );
      this.get_chart_inquiries();
    },
  },
  mounted() {
    this.getDetails();
    document.title = this.$i18n.t("user.Profile");
    this.get_chart_inquiries();

    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;
    this.years.push(currentYear);
    this.selected_year = currentYear;
    while (currentYear >= earliestYear) {
      currentYear -= 1;
      this.years.push(currentYear);
    }
  },
};
</script>
<style lang="css" scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 50px;
}

hr.horizontal {
  opacity: 0.5;
}

.v-avatar {
  overflow: visible;
}

#chartBar {
  min-height: 342px;
}
</style>
